import React from 'react';
import styled from 'styled-components';
import Offers from '../components/Offers';
import ContactFormCom from '../components/ContactFormCom';
import Instagram from '../components/Instagram';
import SEO from '../components/SEO';

const StyledBenefitsWrapper = styled.div`
  margin-top: 150px;
`;

const StyledPageTitle = styled.h1`
  font-weight: ${({ theme }) => theme.font.weight.regular};
  font-size: ${({ theme }) => theme.font.size.s};
  text-align: center;
  text-transform: uppercase;
  font-family: 'Dancing Script', cursive;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

const Oferta = () => (
  <>
    <SEO title="Oferta" />
    <StyledBenefitsWrapper>
      <StyledPageTitle>NASZA OFERTA</StyledPageTitle>
    </StyledBenefitsWrapper>
    <Offers />
    <ContactFormCom />
    <Instagram />
  </>
);

export default Oferta;
